.brown {
  color: #da7a29;
}

.white {
  color: white;
}

.bg-brown {
  background-color: #da7a29;
}

.bg-white {
  background-color: white;
}

.border-bottom {
  border-bottom: 1px solid !important;
  border-bottom-color: #dcdcdc;
}

.border-font-blue {
  border: 1px solid !important;
  border-color: #0839c4;
  color: #0839c4;
  box-shadow: none !important;
}
