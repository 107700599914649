.card {
  box-shadow: 0px 0px 16px rgba(79, 79, 79, 0.08);
  border-radius: 8px;
}

.card-header {
  border-bottom: 0;
}

.table th,
.table td {
  border-top: 0;
  border-bottom: 1px solid;
  border-bottom-color: #d8dbe0;
}

.table thead th {
  border-bottom: 1px solid;
  border-bottom-color: #d8dbe0;
}

.table {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 21, 0.05);
}

.col-input {
  border-radius: 5px !important;
  border: 1px solid;
  border-color: #d8dbe0;
}

.table td {
  vertical-align: middle;
}

.invoice-data-column {
  vertical-align: top !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0;
}
