@font-face {
  font-family: SofiaPro;
  src: url(../assets/fonts/sofia-pro-cdnfonts/Sofia-Pro-Regular-Az.otf);
  font-display: swap;
}

@font-face {
  font-family: LibreBaskerville;
  src: url(../assets/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf);
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: LouisGeorge;
  src: url(../assets/fonts/louis_george_caf/Louis-George-Cafe.ttf);
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

.c-app {
  font-family: SofiaPro;
  background-color: #f2f6fc;
}

.c-main {
  padding-top: 0%;
}

.backgroud {
  background: #e5e5e5;
  font-family: SofiaPro;
  font-style: normal;
  font-weight: normal;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: grey;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  outline: 1px solid slategrey;
}

.flex-theme {
  display: flex;
  flex-direction: column;
}
.header {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 91.67%;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(220, 220, 220, 0.25);
}

.swal2-container .swal2-popup.swal2-toast {
  flex-direction: column;
  align-items: start;
  position: relative;
}

.swal2-container .swal2-icon {
  position: absolute;
  top: 13px;
}

.swal2-icon-default {
  position: relative !important;
  // top: unset !important;
}

.swal2-container .swal2-popup.swal2-toast .swal2-title {
  margin-left: 50px;
  text-align: left;
}

.swal2-container .swal2-popup.swal2-toast .swal2-content {
  margin-left: 34px;
  text-align: left;
}

.logo-position {
  position: absolute;
  left: 4.69%;
  right: 88.07%;
  top: 2.92%;
  bottom: 93.61%;
}

.login-wording,
.forgot-wording {
  position: absolute;
  left: 4.69%;
  right: 68.83%;
  top: 18.75%;
  bottom: 75.69%;
  color: #0839c4;
  size: 35px;
  line-height: 40px;
  letter-spacing: -0.19px;
  font-weight: 400;
  font-family: SofiaPro, sans-serif;
}

.login-alternate,
.forgot-alternate {
  font-size: 13px;
  line-height: 21px;
  position: absolute;
  left: 5%;
  right: 70.78%;
  top: 25%;
  bottom: 72.08%;
  letter-spacing: -0.0691029px;
  color: #313131;
}
.form-position,
.form-register-position,
.form-subscribtion-position,
.form-forgot-password-position {
  position: absolute;
  left: 4.69%;
  right: 60.16%;
  top: 32.08%;
  bottom: 26.53%;

  background: #ffffff;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.form-forgot-password-position {
  display: flex;
  bottom: unset;
  flex-direction: column;
  justify-content: flex-start;
}

.form-subscribtion-position {
  position: absolute;
  display: flex;
  bottom: unset;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}

.form-subscribtion-position > * {
  margin-top: 5%;
  margin-left: 10%;
}

.form-forgot-password-position > * {
  margin-top: 5%;
  margin-left: 10%;
}

.form-subscription-discount {
  display: flex;
  flex-flow: row;
}

.login-rectangle {
  position: absolute;
  left: 45.23%;
  right: 0%;
  top: 0%;
  bottom: 33.19%;

  background: #0839c4;
  border-radius: 0px 0px 0px 300px;
}

.campaign-title,
.subscription-complete-title {
  position: absolute;
  left: 4.69%;
  right: 73.05%;
  top: 18.75%;
  bottom: 75.69%;

  font-size: 35px;
  line-height: 40px;

  /* identical to box height, or 114% */
  letter-spacing: -0.186046px;

  color: #0839c4;
}

.subscription-complete-title-status {
  color: #0839c4;
  font-size: 50px;
  line-height: 120%;
}

.subscription-complete-title-description {
  color: #313131;
  font-size: 20px;
  line-height: 24px;
}
.bottom-rectangle {
  position: absolute;
  left: 45.23%;
  right: 0%;
  top: 40.19%;
  bottom: 0%;
  z-index: -1;

  background: #0839c4;
  border-radius: 0px 0px 0px 300px;
  transform: rotate(-180deg);
}

.form-label {
  font-size: 13px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: -0.0691029px;

  color: #313131;
}

.form-input {
  position: absolute;
  font-size: 19px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: -0.100996px;

  color: #b1b1b1;
  border: none;
  border-bottom: 1px solid grey;
  box-sizing: border-box;
  width: 80%;
}

.form-input-placeholder {
  font-size: 19px;
  line-height: 19px;
}

.form-input:focus {
  outline: none;
  border-bottom: 1px solid #095099;
  color: #2e2f30;
}

.login-button,
.register-button {
  background: #0839c4;
  border-radius: 24.5px;
  border: none;
  color: #ffffff;
}

.register-button {
  position: absolute;
  width: 50%;
  padding: 15px;
  left: 22.6%;
}

.footer,
.subscription-footer {
  border-top: 1px solid #b1b1b1;
  color: #b1b1b1;
  position: absolute;
  width: 100%;
  top: 93.37%;
  bottom: 0.88%;
  vertical-align: middle;
  z-index: -2;
}

.policy-position {
  margin-left: 7%;
  display: flex;
}

.register-button-container {
  position: inherit;
  bottom: 15.59%;
  width: 100%;
}

.register-alternate {
  position: absolute;
  width: 100%;
  left: 6%;
  right: 60.16%;
  bottom: 25.6%;
}

.login-register-alternate {
  font-size: 13px;
  line-height: 21px;
  position: absolute;
  left: 5%;
  right: 70.78%;
  top: 25%;
  bottom: 72.08%;
  letter-spacing: -0.0691029px;
  color: #313131;
}

.tick:before {
  content: "\2713";
  font-size: inherit;
  color: #0839c4;
}

.discount-container {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  width: 50%;
}

.discount-container > * {
  margin: 4%;
}

.discount-container > div {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.discount-label {
  font-weight: bold;
}

.discount-code-input {
  width: 80%;
}

.link-border-bottom {
  color: #0839c4;
  border-bottom: 1px solid #0839c4;
  padding-bottom: 2 px;
  cursor: pointer;
}

.redeem {
  position: inherit;
  left: 85.22%;
  bottom: 20%;
}

.total-payment {
  width: 30%;
  margin-left: 4px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.0691029px;
  color: "#313131";

  background: #e1e7f8;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
}

.service-features {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.service-features > label {
  flex: 50%;
  font-size: 18px;
}

.subscription-button-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 4%;
}

.subscription-button-container > button {
  width: 50%;
  padding: 15px;
}

.subscription-complete-rectangle {
  position: absolute;
  left: 45.23%;
  right: 0%;
  top: 0%;
  bottom: 33.19%;

  background: #0839c4;
  border-radius: 0px 0px 0px 300px;
}

.subscription-complete-rafiki,
.reset-password-rafiki {
  position: absolute;
  left: 50.7%;
  width: 40%;
  right: 8.72%;
  top: 32.08%;
  bottom: 14.17%;
}

.strikediag {
  background: linear-gradient(
    to center center,
    transparent 47.75%,
    red,
    red,
    transparent 52.25%
  );
}

.withpadding {
  padding: 0 0.15em;
}

.action-button {
  background: transparent;
  color: #0839c4;
  margin-right: 8px;
  margin-bottom: 4px;
  border-radius: 50%;
  width: 41px;
  height: 41px;
}

.action-button:hover {
  background-color: #0839c4;
  color: white;
}

.page-link,
.page-item {
  color: #0839c4;
}

.page-item.active .page-link {
  background-color: #0839c4;
}

.form-control,
.custom-select {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #e4e7ea;
}

.add-new-summary {
  margin-bottom: 4px;
  cursor: pointer;
}

.hover-underline-animation {
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0839c4;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-underline-animation-menu {
  position: relative;
}

.hover-underline-animation-menu:active {
  background-color: #da7a29;
}

.hover-underline-animation-menu:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #da7a29;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-menu:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #0839c4;
}

.disable-transparent:disabled {
  background-color: transparent;
}

.submit-button-child-left {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
  width: 70%;
}

.submit-button-child-left::after {
  content: "";
  background: #fff;
  position: absolute;
  bottom: 25%;
  right: 0;
  height: 70%;
  width: 2px;
  top: 15%;
}

.submit-button-child-right {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
  width: 30%;
}

.dropdown-menu {
  position: absolute !important;
}

.table-responsive {
  overflow: visible;
  overflow-x: scroll;
}
.project-context {
  margin: 0;
  padding: 0;
}

.editable-btn button {
  margin-right: 4px;
}

.editable-btn button {
  margin-right: 4px;
}

.editable-select {
  border: 1px solid !important;
  border-color: #e4e7ea !important;
  border-radius: 0.25rem;
}

.container-fluid {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.project-context {
  width: unset;
  max-width: 100%;
  flex-grow: 2;
}

@media (max-width: 768px) {
  .flex-theme {
    align-items: center;
  }
  .flex-theme > * {
    position: unset;
  }

  .flex-theme > .logo-position {
    height: 50px;
    padding-top: 11px;
    padding-bottom: 11px;
    background-color: #ffffff;
    width: 100%;
  }

  .flex-theme > .campaign-title {
    width: 90%;
  }

  .flex-theme > .subscription-footer {
    z-index: unset;
  }

  .login-rectangle,
  .bottom-rectangle,
  .rafiki {
    display: none;
  }

  .logo-position {
    width: 50%;
    left: 25%;
  }

  .login-wording,
  .campaign-title,
  .login-alternate,
  .login-register-alternate {
    font-size: 24px;
    line-height: 40px;
    width: max-content;
  }

  .login-wording,
  .campaign-title {
    top: 12.67%;
  }

  .login-alternate {
    top: 18.12%;
  }

  .login-register-alternate {
    width: 95%;
    top: 18.12%;
    font-size: 13px;
    line-height: 21px;
  }

  .register-button {
    width: 50%;
  }

  .policy-position {
    width: inherit;
    bottom: 17.79%;
  }
  .policy-position h6 {
    line-height: 16px;
    font-size: 12px;
    font-weight: normal;
  }

  .register-button-container {
    bottom: 18.59%;
  }

  .form-position,
  .form-register-position,
  .form-subscribtion-position,
  .form-forgot-password-position {
    left: 5.87%;
    right: 5.87%;
    top: 33.98%;
    bottom: 12.7%;
  }

  .form-forgot-password-position {
    top: 59%;
    bottom: unset;
  }
  .form-subscribtion-position {
    width: 90%;
    bottom: unset;
    margin-bottom: 8%;
  }

  .form-subscribtion-position > * {
    padding-right: 10%;
  }

  .form-position {
    top: 23.98%;
    bottom: 32.7%;
  }
  .form-register-position {
    top: 23.98%;
    bottom: 13.9%;
  }

  .register-alternate {
    position: absolute;
    left: 4.69%;
    right: 60.16%;
    bottom: 20.6%;
    top: 88%;
    width: auto;
  }

  .service-features {
    flex-flow: column;
    margin-top: 2%;
    margin-left: 10%;
    align-content: space-around;
  }
  .form-subscription-discount {
    flex-direction: column;
  }
  .discount-container,
  .total-payment {
    width: 100%;
  }

  .total-payment {
    margin: unset;
    margin-top: 4px;
  }

  .redeem {
    margin-left: 4px;
  }
  .service-features > label {
    flex: 50%;
    font-size: 13px;
  }

  .subscription-button-container > button {
    width: unset;
  }

  .subscription-complete-rectangle {
    position: absolute;
    left: 9.33%;
    right: 0%;
    top: 8.17%;
    bottom: 53.27%;
  }

  .subscription-complete-rafiki {
    position: absolute;
    right: unset;
    left: unset;
    top: 15%;
    width: 292.57px;
    height: 218px;
  }

  .subscription-complete-title {
    position: absolute;
    width: 80%;
    height: 78px;
    left: 10%;
    top: 60%;
    font-size: 12px;
    line-height: 16px;

    /* or 26px */
    letter-spacing: -0.116943px;
  }

  .subscription-complete-title-status,
  .forgot-wording {
    font-size: 22px;
    line-height: 120%;

    /* or 26px */
    letter-spacing: -0.116943px;
  }

  .subscription-complete-title-description,
  .forgot-alternate {
    font-size: 12px;
    line-height: 16px;

    /* or 133% */
    letter-spacing: -0.106312px;
  }

  .forgot-alternate,
  .forgot-wording {
    width: 80%;
  }
  .forgot-wording {
    top: 53%;
  }

  .forgot-alternate {
    top: 57%;
    margin-bottom: 4px;
  }
  .footer {
    bottom: 0.1%;
  }

  .dropdown-setting {
    margin: 0% !important;
  }

  .table-responsive {
    overflow: scroll;
  }
}

@media (max-width: 568px) {
  .login-rectangle,
  .rafiki {
    display: none;
  }

  .logo-position {
    width: 50%;
    left: 25%;
  }

  .login-wording,
  .login-alternate {
    font-size: 24px;
    line-height: 40px;
  }

  .login-alternate {
    font-size: 13px;
    line-height: 21px;
    width: 90%;
  }

  .form-position {
    top: 27.98%;
    bottom: 24.7%;
  }
  .form-register-position {
    top: 25.98%;
    bottom: 11.9%;
  }

  .register-alternate {
    bottom: 6.6%;
    right: 0%;
  }
  .register-button-container {
    bottom: 15.09%;
  }

  .action-button {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .submit-button {
    width: 100% !important;
  }

  .container-fluid {
    padding-left: 4px;
    padding-right: 4px;
  }
}
