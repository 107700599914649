@font-face {
  font-family: SofiaPro;
  src: url(../assets/fonts/sofia-pro-cdnfonts/Sofia-Pro-Regular-Az.otf);
  font-display: swap;
}

.cross-flex {
  display: inherit;
  flex-direction: column;
}
.inline-flex {
  display: flex;
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}
.align-center {
  align-content: center;
}

.space-evenly {
  justify-content: space-evenly;
}
.flex-copywrite {
  margin-left: 4.56%;
}

.flex-theme {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  position: absolute;
  overflow: auto;
  min-width: 100%;
  min-height: 100%;

  background: #f2f6fc;
  font-family: SofiaPro;
  font-style: normal;
  font-weight: normal;
  z-index: -99;
}

.flex-theme .form-input {
  position: unset;
}

.flex-theme .register-button {
  position: unset;
}

.flex-theme .policy-position {
  position: unset;
}

.flex-theme .register-alternate {
  all: unset;
  width: auto;
  order: 3;
}

.flex-header {
  flex: 0 0 160px;
}

.flex-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 4.56%;
  min-height: max-content;
  // overflow: auto;
}

.flex-footer {
  flex-direction: row;
  align-items: center;
  flex: 0 1 50px;

  border-top: 1px solid #b1b1b1;
  color: #b1b1b1;
  background: inherit;
}

.flex-footer > * {
  flex: 33.33%;
}

.flex-title-container {
  display: inherit;
  flex-direction: column;
  align-items: flex-start;
}

.flex-title-container h1 {
  color: #0839c4;
  size: 35px;
  line-height: 40px;
  letter-spacing: -0.19px;
  font-weight: 400;
  font-family: SofiaPro, sans-serif;
}

.flex-title-container h6 {
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.0691029px;
  color: #313131;
}

.flex-left-align {
  margin-right: 60.16%;
  margin-bottom: 8px;
}

.flex-left-align-wide {
  margin-right: 50.16%;
  margin-bottom: 8px;
}

.flex-form-container {
  background: #ffffff;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.flex-form-container > * {
  width: unset;
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 4%;
}

.flex-form-container :nth-child(1) {
  margin-top: 5%;
}

@media (max-width: 768px) {
  .flex-body {
    margin-right: 4.56%;
  }
  .flex-header {
    flex: 0 0 100px;
  }
  .flex-left-align,
  .flex-left-align-wide {
    margin-right: unset;
  }
  .inline-flex {
    flex-direction: column;
  }
  .flex-footer {
    width: 100%;
  }

  .lower-half-position {
    justify-content: flex-end;
  }

  .subscription-complete-rafiki {
    height: 218px;
    width: 90%;
    z-index: 1;
  }

  .flex-body > .reset-password-rafiki {
    all: unset;
    height: 218px;
    width: 90%;
    z-index: 1;
  }
}

@media (max-width: 568px) {
}
