.c-sidebar {
  background: #ffffff;
}

.c-sidebar .c-sidebar-brand {
  background: #ffffff;
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #000000;
  border-radius: 8px;
  margin-left: 22px;
  margin-right: 18px;
  margin-bottom: 6px;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: #da7a29;
  color: #000000;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  padding-left: 10px;
  padding-right: 1px;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: #da7a29;
  color: #000000;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #000000;
}

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  white-space: unset;
}

.c-sidebar-nav-dropdown {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: #e9af7f;
  color: #000000;
  border-radius: 8px;
  margin-left: 22px;
  margin-right: 18px;
  margin-bottom: 6px;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #000000;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(0, 0, 0, 1)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.action-menu {
  // color: ;
  background: #e9af7f;
  border-radius: 100px;
}

.action-menu:hover {
  // color: #ffffff;
  background: #da7a29;
}

.add-new {
  margin-left: 22px;
  margin-right: 18px;
  margin-bottom: 24px;
  margin-top: 24px;
  color: #ffffff;
  background: #0839c4;
  border-radius: 100px;
}

.add-new:hover {
  color: #ffffff;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover {
  background-color: transparent;
}

@media (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-fixed,
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-fixed {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-sm-show.c-sidebar-fixed,
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-fixed {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-md-show.c-sidebar-fixed,
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-fixed {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-lg-show.c-sidebar-fixed,
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-fixed {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@media (min-width: 1200px) and (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-xl-show.c-sidebar-fixed,
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-fixed {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@media (min-width: 1400px) and (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-xxl-show.c-sidebar-fixed,
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-fixed {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
