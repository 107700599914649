.c-header,
.c-subheader,
.c-footer {
  background-color: #f2f6fc;
}

.c-app:not(.c-legacy-theme) .c-header.c-header-fixed {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.c-header .c-subheader {
  border: 0;
}
